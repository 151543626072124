
import CanvasTitle from './components/CanvasTitle.vue'
import("./assets/wasm/wasm.js").then(module => {
  // Runs the game
  module.default(document.location.href + 'assets/wasm_bg.wasm')
});

export default {
  name: 'App',
  components: {
    CanvasTitle
  }
}
